* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.preguntas,
.noticias,
.contacto,
.sesion {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

p {
  display: flex;
  justify-content: left;
  text-align: justify;
  padding: 20px;
}
/* 
li {
  display: flex;
  justify-content: left;
  text-align: justify;
  padding: 20px;
} */

.preguntas {
  /* background-image: url("/images/img-2.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  /* font-size: 100px; */
}

.noticias {
  background-image: url("/images/img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contacto {
  background-image: url("/images/img-9.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sesion {
  background-image: url("/images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.justify {
  display: flex;
  justify-content: center;
  text-align: justify;
  padding: 20px;
}
/* .faq-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
  text-align: center;
} */
.faq-container h3 {
  width: 100%;
  max-width: 800px;
  margin: auto;
  text-align: left;
}
.faq-container p {
  width: 100%;
  max-width: 800px;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  align-items: justify;
}
.faq-container li {
  width: 100%;
  max-width: 650px;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  align-items: justify;
}
.faq-container {
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: auto;
  padding-left: 10px;
  font-family: Arial, Helvetica, sans-serif;
}
.estatutos {
  display: flex;
  justify-content: center;
  text-align: justify;
  padding: 20px;
}
.asociacion {
  display: flex;
  justify-content: center;
  text-align: justify;
  padding: 20px;
}
